import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import CleanerList from '@components/common/cleaner_list'
import CompanyFilter from '@components/funnel/company_filter'
import $ from "jquery";

const SORTING_CRITERIA = {
    no_criteria: 0,
    domestina_rank: 1,
    price: 2,
    rating: 3,
};

class CleanerSelector extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        onSelect: PropTypes.func,
        ctaButtonText: PropTypes.string,

        highlightedCleanerId: PropTypes.number,
        highlightedCleanerError: PropTypes.string,

        selectedFilter: PropTypes.string,
        selectedFilterDate: PropTypes.instanceOf(Date),
        handleFilterSelect: PropTypes.func,

        promotionSeed: PropTypes.number,

        buyAddressMembership: PropTypes.bool,
        isCurrentAddressMember: PropTypes.bool,

        openCleanerProfileDialog: PropTypes.func,

        hasSelectedFilterDate: PropTypes.bool,

        waitingListClicked: PropTypes.func,

        switchToFlexClicked: PropTypes.func,

        amplitudeContext: PropTypes.string,
        amplitudeProperties: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            sortingCriteria: this.initialSortingCriteria(),
        };
    };

    componentDidMount() {
        if(this.props.amplitudeContext)
            amplitude.track('see page - ' + this.props.amplitudeContext +  ' cleaner selection', this.props.amplitudeProperties);
    }


    initialSortingCriteria = () => {
        return SORTING_CRITERIA['price'];
        // return SORTING_CRITERIA['domestina_rank'];
    };


    regularCleaners = () => {
        let sortedCleaners;
        if(this.state.sortingCriteria === SORTING_CRITERIA['price']) {
            sortedCleaners = this.sortedByPrice();
        } else {
            sortedCleaners = this.sortedByDomestina();

        }

        // if(this.props.selectedFilter === "all"){
        sortedCleaners = this.separateByAvailability(sortedCleaners);
        // sortedCleaners = this.separateByEntity(sortedCleaners);
        sortedCleaners = this.pushInitialFavoritesFirst(sortedCleaners);
        sortedCleaners = this.pushPreviousChoiceFirst(sortedCleaners);
        // }

        return sortedCleaners;
    };

    separateByEntity = (cleaners) => {
        let teamCleaners = _.filter(cleaners, function(cleaner){ return cleaner.entityType === 'team_entity'; });
        let individualCleaners = _.filter(cleaners, function(cleaner){ return cleaner.entityType !== 'team_entity'; });
        return teamCleaners.concat(individualCleaners);
    }

    pushInitialFavoritesFirst = (cleaners) => {
        var initialFavorites = _.filter(cleaners, function(cleaner){ return cleaner.initialFavorite; });
        if(initialFavorites.length > 0) {

            // remove the initial favorites
            var cleanersRearranged = _.difference(cleaners, initialFavorites);
            // add the initial favorites up front
            _.each(initialFavorites, function(favCleaner){ cleanersRearranged.unshift(favCleaner); })

            return cleanersRearranged;
        } else
            return cleaners;
    };

    pushPreviousChoiceFirst = (cleaners) => {
        var prevChoice = _.find(cleaners, function(cleaner){ return cleaner.previous_choice; });
        if(prevChoice) {
            // remove the promoted company
            var cleanersRearranged = _.without(cleaners, prevChoice);
            // add the promoted company up front
            cleanersRearranged.unshift(prevChoice);
            return cleanersRearranged;
        } else
            return cleaners;
    };

    cleanersWithPromotedSecond = () => {
        var promotedCleaner = this.findPromotedCleaner();
        if(promotedCleaner && this.props.cleaners.length > 2 && promotedCleaner.ranking.domestina_rank > 2) {
            // remove the promoted cleaner
            var cleaners = _.without(this.props.cleaners, promotedCleaner);
            // put the promoted cleaner at second place
            cleaners.splice(1, 0, promotedCleaner);
            return cleaners;
        } else {
            return this.props.cleaners;
        }
    };


    findPromotedCleaner = () => {
        let promotedCleaners = _.filter(this.props.cleaners, function(cleaner){ return cleaner.promoted && !cleaner.previous_choice; });
        if(promotedCleaners.length > 0) {
            return promotedCleaners[this.props.promotionSeed % promotedCleaners.length];
        } else {
            return null;
        }
    };

    showAvailabilitySeparators = () => {
        return true;
    };

    hasPromotedCleaner = () => {
        return !!this.findPromotedCleaner();
    };

    showRecommendedBadge = () => {
        return false;
        // return this.state.sortingCriteria === SORTING_CRITERIA['domestina_rank'];
    };

    showPromotedProfile = () => {
        return true;
    };

    buttonName = () => {
        return this.props.ctaButtonText || i18n.t('funnel.companies.schedule');
    };

    onSelect = (cleanerId) => {
        this.props.onSelect(cleanerId, this.findCleanerRank(cleanerId), this.state.sortingCriteria, this.showPromotedProfile());
    };

    findCleanerRank = (cleanerId) => {
        return _.findIndex(this.regularCleaners(), function (cleaner) {
            return cleaner.id === cleanerId
        }) + 1;
    };

    sortingOptions = () => {
        var result = _.map(_.omit(SORTING_CRITERIA, 'no_criteria', 'rating'), function (v, k) {
            return {value: v, label: i18n.t('funnel.companies.sorting-criteria.' + k)};
            // isDisabled: !this.addressSupportsService(address, this.selectedService().id)}
        }.bind(this));
        return result;
    };

    selectSortingCriteria = (sortingCriteria) => {
        this.setState({sortingCriteria: sortingCriteria});
    };

    sortedByDomestina = () => {
        return _.sortBy(this.props.cleaners, function(cleaner) {
            return cleaner.ranking.domestina_rank;
        });
    };

    sortedByPrice = () => {
        return _.sortBy(this.sortedByDomestina(), function(cleaner) {
            return parseFloat(cleaner.ranking.price);
        });
    };

    separateByAvailability = (cleaners) => {
        let availableNext7 = _.filter(cleaners, function(cleaner){ return cleaner.availableNext7Days; });
        let available7plus = _.filter(cleaners, function(cleaner){ return !cleaner.availableNext7Days; });
        return availableNext7.concat(available7plus);
    }

    shouldHideFilter = () => {
        return (this.regularCleaners().length === 0 && this.props.selectedFilter === 'all');
    }

    render() {
        return (
            <div className="company-selector">
                <div>
                    {this.props.children}
                </div>

                {!this.shouldHideFilter() &&
                    <div className="row">
                        <div className="col-xs-12">
                            <div className='company-selector-filter-container'>
                                <CompanyFilter selectedFilter={this.props.selectedFilter}
                                               selectedFilterDate={this.props.selectedFilterDate}
                                               handleFilterSelect={this.props.handleFilterSelect}
                                               sortingOptions={this.sortingOptions()}
                                               selectedSortingCriteria={this.state.sortingCriteria}
                                               handleSortingSelect={this.selectSortingCriteria}
                                />
                            </div>
                        </div>
                    </div>}



                <div className="company-list-container">
                    <CleanerList cleaners={this.regularCleaners()}
                                 onSelect={this.onSelect}
                                 selectBtnText={this.buttonName()}
                                 profileSelectBtnText={i18n.t('form.buttons.choose')}
                                 showRecommendedBadge={this.showRecommendedBadge()}
                                 showPromotedProfile={this.showPromotedProfile()}
                                 promotedCleaner={this.findPromotedCleaner()}
                                 showAvailabilitySeparators={this.showAvailabilitySeparators()}
                                 isCurrentAddressMember={this.props.isCurrentAddressMember}
                                 buyAddressMembership={this.props.buyAddressMembership}
                                 openCleanerProfileDialog={this.props.openCleanerProfileDialog}
                                 hasSelectedFilterDate={this.props.hasSelectedFilterDate}
                                 highlightedCleanerId={this.props.highlightedCleanerId}
                                 highlightedCleanerError={this.props.highlightedCleanerError}
                    />
                    {this.props.switchToFlexClicked &&
                        <div className="switch-to-flex-container">
                            <button className={"btn btn-primary"} onClick={this.props.switchToFlexClicked}>
                                {i18n.t("funnel.switch-to-flex-btn")}
                            </button>
                        </div>
                    }
                    {this.props.waitingListClicked &&
                        <div className="switch-to-flex-container">
                            <button className={"btn btn-primary"} onClick={this.props.waitingListClicked}>
                                {i18n.t("funnel.notify-me-when-maid-available")}
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default CleanerSelector;
