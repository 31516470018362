import React from 'react';
import PropTypes from 'prop-types';

import _ from 'underscore';
import CleanerBox from '@components/common/cleaner_box'

class CleanerList extends React.Component {
    static propTypes = {
        cleaners: PropTypes.arrayOf(PropTypes.object),
        showRecommendedBadge: PropTypes.bool,
        showPromotedProfile: PropTypes.bool,

        highlightedCleanerId: PropTypes.number,
        highlightedCleanerError: PropTypes.string,

        onSelect: PropTypes.func,
        selectBtnText: PropTypes.string,
        profileSelectBtnText: PropTypes.string,
        promotedCleaner: PropTypes.object,

        buyAddressMembership: PropTypes.bool,
        isCurrentAddressMember: PropTypes.bool,

        openCleanerProfileDialog: PropTypes.func,

        hasSelectedFilterDate: PropTypes.bool,

        showAvailabilitySeparators: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            cleanersShowCount: 6,
            showMaidsAfterHighlighted: false
        };
    }


    showMaidsAfterHighlighted = () => {
        this.setState({showMaidsAfterHighlighted: true});
        return false;
    }

    regularCleaners = () => {
        return _.reject(this.props.cleaners, function(cleaner){
            return cleaner.id === this.props.highlightedCleanerId;
        }.bind(this));
    }

    highlightedCleaner = () => {
        return _.find(this.props.cleaners, function(cleaner) {
            return cleaner.id === this.props.highlightedCleanerId;
        }.bind(this));
    }

    hasHighlightedCleaner = () => {
        return this.highlightedCleaner() != null;
    }

    showRegularCleaners = () => {
        return !this.hasHighlightedCleaner() || this.state.showMaidsAfterHighlighted;
    }

    increaseCleanersShowCount = () => {
      this.setState({cleanersShowCount: this.state.cleanersShowCount + 6})
    };

    hasMoreCleaners = () => {
      return this.regularCleaners().length > this.state.cleanersShowCount;
    };

    collectionTopRank = () => {
        return  _.min(_.map(this.regularCleaners(),
                                function(cleaner){ return cleaner.ranking.domestina_rank}));
    };

    showPromoted = (cleaner) => {
        return this.props.showPromotedProfile && this.props.promotedCleaner && this.props.promotedCleaner.id === cleaner.id;
    }

    renderCleanerBox = (cleaner, i) => {
        return (
            <CleanerBox cleaner={cleaner}
                        onSelect={this.props.onSelect}
                        selectBtnText={this.props.selectBtnText}
                        showRecommendedBadge={this.props.showRecommendedBadge}
                        showPromotedProfile={this.showPromoted(cleaner)}
                        profileSelectBtnText={this.props.profileSelectBtnText}
                        collectionTopRank={this.collectionTopRank()}
                        isCurrentAddressMember={this.props.isCurrentAddressMember}
                        buyAddressMembership={this.props.buyAddressMembership}
                        openCleanerProfileDialog={this.props.openCleanerProfileDialog}
                        key={i}/>
        )
    };

    renderRegularCleaners = () => {
        let shownFirstSeparator = false;
        let shownSecondSeparator = false;

        return (
            <div>
                {_.map(this.regularCleaners().slice(0, this.state.cleanersShowCount), function (cleaner, i) {
                    if(this.props.showAvailabilitySeparators && !cleaner.previous_choice && !shownFirstSeparator && cleaner.availableNext7Days && !cleaner.initialFavorite){
                        shownFirstSeparator = true;
                        return (
                            <div key={i}>
                                <div className={'cleaners-separator '}>
                                    {i18n.t("funnel.cleaners.separators.available-in7")}
                                </div>
                                {this.renderCleanerBox(cleaner, i)}
                            </div>
                        )
                    } else if(this.props.showAvailabilitySeparators && !cleaner.previous_choice && !shownSecondSeparator && !cleaner.availableNext7Days && !cleaner.initialFavorite) {
                        shownSecondSeparator = true;
                        return (
                            <div key={i}>
                                <div className={'cleaners-separator '}>
                                    {i18n.t("funnel.cleaners.separators.available-more7")}
                                </div>
                                {this.renderCleanerBox(cleaner, i)}
                            </div>
                        )
                    } else {
                        return (
                            this.renderCleanerBox(cleaner, i)
                        );
                    }
                }.bind(this))}
                {this.hasMoreCleaners() &&
                <div className={'show-all-companies-container'}>
                    <a onClick={this.increaseCleanersShowCount}
                       style={{cursor: "pointer"}}> {i18n.t('funnel.cleaners.show-all-cleaners')} </a>
                </div>}
            </div>
        );
    };


    renderEmptyList = () => {
        if(this.props.hasSelectedFilterDate)
            return (
                <h4>{i18n.t('funnel.companies.no-providers-for-date')}</h4>
            );
        else
            return (
                <h4>{i18n.t('funnel.companies.no-providers')}</h4>
            );
    };

    renderHighlightedError = () => {
        return (
            <div className={'missing-housemaid-error-container'}>
                {this.props.highlightedCleanerError}
            </div>
        )
    }

    renderHighlightedCleaner = () => {
        return (
            this.renderCleanerBox(this.highlightedCleaner(), 0)
        )
    }

    renderShowOthersButton = () => {
        return (
            <div className="show-all-companies-container">
                <a href={'#'} onClick={this.showMaidsAfterHighlighted}>
                    {i18n.t('funnel.cleaners.show-other-cleaners')}
                </a>
            </div>
        )
    }

    render() {
        if (this.props.cleaners.length >= 1) {
            return (
                <div className="company-list">
                    {this.props.highlightedCleanerError && this.renderHighlightedError()}

                    {this.hasHighlightedCleaner() && this.renderHighlightedCleaner()}

                    {this.showRegularCleaners() && this.renderRegularCleaners()}

                    {!this.showRegularCleaners() && this.renderShowOthersButton()}
                </div>
            )
        } else {
            return (
                <div className="company-list">
                    {this.props.highlightedCleanerError && this.renderHighlightedError()}

                    {this.renderEmptyList()}
                </div>
            )
        }
    }
}

export default CleanerList;
